import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHomeUser } from "@fortawesome/free-solid-svg-icons";

//import { faSearch } from "@fortawesome/free-solid-svg-icons";

import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import ThemeProvider from "react-bootstrap/ThemeProvider";
import FormGroup from "react-bootstrap/FormGroup";

const FormUser = () => {
  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint="xxs"
    >
      <Container fluid="xxs">
        <Navbar id="container-nav" expand="lg">
          <Container fluid>
            <Navbar.Brand href="#">
              <Link to="/profile" aria-current="page">
                <FontAwesomeIcon icon={faHomeUser} className="user-icon" />
              </Link>
            </Navbar.Brand>
            <Navbar.Brand>
              <Link to="/userone" aria-current="page" id="name">
                DROP Cycling&trade;
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              className="navbar-toggler"
              id="border"
            >
              <FontAwesomeIcon icon={faBars} id="toggle" />
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              ></Nav>
              <Form className="d-flex" id="search">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                />
                <Button variant="outline-none" id="search-btn">
                  Search
                </Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Form className="user">
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <FormGroup className="mb-3">
                <Form.Control
                  form-control-md="true"
                  type="input"
                  placeholder="First Name"
                  id="first-name"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <FormGroup className="mb-3">
                <Form.Control
                  form-control-md="true"
                  type="input"
                  placeholder="Last Name"
                  id="last-name"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <FormGroup className="mb-3">
                <Form.Control
                  type="input"
                  placeholder="Email"
                  id="form-email"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <FormGroup className="mb-3">
                <Form.Control
                  type="input"
                  placeholder="123-456-7890"
                  id="form-number"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <FormGroup className="mb-3">
                <Form.Select
                  aria-label="Default select example"
                  size="lg"
                  id="form-select"
                >
                  <option>Select</option>
                  <option value="road">Road</option>
                  <option value="mtbike">MtBike</option>
                  <option value="bmx">BMX</option>
                </Form.Select>
              </FormGroup>
            </Col>
            <Col></Col>
          </Row>

          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <FormGroup className="mb-3">
                <Form.Control
                  type="input"
                  size="lg"
                  placeholder="About"
                  id="form-about"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <FormGroup className="mb-3">
                <Button className="custom-button-form">
                  <Link to="/userone" className="linkto">
                    Send
                  </Link>
                </Button>
              </FormGroup>
            </Col>
          </Row>

          <footer>
            <Row>
              <Col sm={{ span: 6, offset: 3 }}>
                <p className="copy">Copyright &copy; 2023</p>
              </Col>
            </Row>
          </footer>
        </Form>
      </Container>
    </ThemeProvider>
  );
};

export default FormUser;
