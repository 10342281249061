import React from "react";
//router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ThemeProvider from "react-bootstrap/ThemeProvider";

//components
//import Load from "./components/load/load";
import SignIn from "./components/signin/signin";

import FormUser from "./components/form/formuser";
import UserOne from "./components/userone/userone";
import Distance from "./components/distance";

import Bpm from "./components/bpm/bpm";
import Calories from "./components/calories/calories";
import Hydration from "./components/hydration/hydration";

import Profile from "./components/profile/profile";
import Crew from "./components/crew/crew";
import Time from "./components/time/time";
import Message from "./components/message/message";
import Shop from "./components/shop/shop";

function App() {
  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint="xxs"
    >
      <Router>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="formuser" element={<FormUser />} />
          <Route path="userone" element={<UserOne />} />
          <Route path="distance" element={<Distance />} />
          <Route path="bpm" element={<Bpm />} />
          <Route path="calories" element={<Calories />} />
          <Route path="hydration" element={<Hydration />} />
          <Route path="profile" element={<Profile />} />
          <Route path="crew" element={<Crew />} />
          <Route path="time" element={<Time />} />
          <Route path="message" element={<Message />} />
          <Route path="shop" element={<Shop />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
