import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ThemeProvider from "react-bootstrap/ThemeProvider";

import brand from "../brand/brand.png";

//routes
import { Outlet, Link } from "react-router-dom";

function SignIn() {
  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint="xl"
    >
      <Container fluid="xxs">
        <div id="signin">
          <Row>
            <Col sm={{ span: 6, offset: 6 }}>
              <img src={brand} className="img-fluid" alt="logo" />
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 9, offset: 1.5 }}>
              <h1>Welcome to Drop Cycling! </h1>
              <p className="welcome">
                We hope you find yourself immersed within the software on your
                every ride, with any cycling discipline. BMX racing, Road Tours,
                Cyclocross, Mt Bike.
              </p>
              <p className="welcome-two">
                Once you sign-up you will be able to record your stats on each
                trek out including heart rhythms, calories burned, distance and
                time spent. This will allow you study the data and find your
                failures and your success as you grow as a cyclist.
              </p>

              <Col sm={{ span: 6, offset: 3 }}>
                <Button className="custom-button ">
                  <Link to="/formuser" id="text-link">
                    Join
                  </Link>
                </Button>
              </Col>
              <Col sm={{ span: 6, offset: 3 }}>
                <Button className="custom-button-two ">
                  <Link to="/userone" id="text-link">
                    LogIn
                  </Link>
                </Button>
              </Col>
              <Outlet />
            </Col>
          </Row>
          <footer>
            <Row>
              <Col sm={{ span: 6, offset: 3 }}>
                <p className="copy">Copyright &copy; 2023</p>
              </Col>
            </Row>
          </footer>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default SignIn;
