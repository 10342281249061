import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import Form from "react-bootstrap/Form";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import ThemeProvider from "react-bootstrap/ThemeProvider";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHomeUser, faUsers } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

function Message() {
  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint="xxs"
    >
      <Container fluid="xxs">
        <Navbar id="container-nav" expand="lg">
          <Container fluid>
            <Navbar.Brand href="#">
              <Link to="/profile" aria-current="page">
                <FontAwesomeIcon icon={faHomeUser} className="user-icon" />
              </Link>
            </Navbar.Brand>
            <Navbar.Brand>
              <Link to="/userone" aria-current="page" id="name">
                DROP Cycling&trade;
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              className="navbar-toggler"
              id="border"
            >
              <FontAwesomeIcon icon={faBars} id="toggle" />
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              ></Nav>
              <Form className="d-flex" id="search">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                />
                <Button variant="outline-none" id="search-btn">
                  Search
                </Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="user">
          <Row className="g-4">
            <Col sm={{ span: 10, offset: 1 }}>
              <p className="message-pagination">
                <FontAwesomeIcon icon={faUsers} />
              </p>
            </Col>
          </Row>

          <Card className="card-design-message">
            <Row className="g-4">
              <Col sm={{ span: 12 }}>
                <p className="sub-topic-message">Messages</p>
              </Col>
            </Row>
          </Card>
          <Card className="card-design-message-two">
            <Row>
              <Col sm={{ span: 10, offset: 1 }}>
                <Accordion defaultActiveKey="0" id="message-stack">
                  <Accordion.Header>Welcome</Accordion.Header>
                  <Accordion.Body>
                    <p className="basic-text-message">
                      Welcome aboard! We are glad you chose Drop Software. Send
                      us a message if you get stuck, want to say hello, or have
                      an opinion on how we can do better.
                    </p>
                  </Accordion.Body>
                </Accordion>
              </Col>
            </Row>
          </Card>
          <footer>
            <Row>
              <Col sm={{ span: 6, offset: 3 }}>
                <p className="copy">Copyright &copy; 2023</p>
              </Col>
            </Row>
          </footer>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default Message;
