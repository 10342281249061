import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import Form from "react-bootstrap/Form";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import ThemeProvider from "react-bootstrap/ThemeProvider";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faHomeUser,
  faTShirt,
  faShop,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

function Shop() {
  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint="xxs"
    >
      <Container fluid="xxs">
        <Navbar id="container-nav" expand="lg">
          <Container fluid>
            <Navbar.Brand href="#">
              <Link to="/profile" aria-current="page">
                <FontAwesomeIcon icon={faHomeUser} className="user-icon" />
              </Link>
            </Navbar.Brand>
            <Navbar.Brand>
              <Link to="/userone" aria-current="page" id="name">
                DROP Cycling&trade;
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              className="navbar-toggler"
              id="border"
            >
              <FontAwesomeIcon icon={faBars} id="toggle" />
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              ></Nav>
              <Form className="d-flex" id="search">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                />
                <Button variant="outline-none" id="search-btn">
                  Search
                </Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="user">
          <Row className="g-4">
            <Col sm={{ span: 10, offset: 1 }}>
              <p className="shop-pagination">
                <FontAwesomeIcon icon={faShop} />
              </p>
            </Col>
          </Row>

          <Card className="card-design-shop">
            <Row className="g-4">
              <Col sm={{ span: 12 }}>
                <p className="sub-topic-shop">Shop</p>
              </Col>
            </Row>
          </Card>

          <Card className="card-design-shop-two">
            <Row>
              <Col sm={{ span: 4, offset: 1 }}>
                <p className="shop-header">T-Shirts</p>
              </Col>
            </Row>
          </Card>

          <Row xs={1} md={3} className="g-4">
            <Col sm={{ span: 4, offset: 1 }}>
              <Link to="/hydration" aria-current="page" className="nav-link">
                <Card className="card-design-shop-product">
                  <FontAwesomeIcon icon={faTShirt} />
                  <div className="card-nav-shop"></div>
                </Card>
              </Link>
            </Col>
          </Row>
          <Row xs={1} md={3} className="g-4">
            <Col sm={{ span: 4, offset: 1 }}>
              <Link to="/hydration" aria-current="page" className="nav-link">
                <Card className="card-design-shop-product-two">
                  <FontAwesomeIcon icon={faTShirt} />
                  <div className="card-nav-shop"></div>
                </Card>
              </Link>
            </Col>
          </Row>
          <Row xs={1} md={3} className="g-4">
            <Col sm={{ span: 4, offset: 1 }}>
              <Link to="/hydration" aria-current="page" className="nav-link">
                <Card className="card-design-shop-product-three">
                  <FontAwesomeIcon icon={faTShirt} />
                  <div className="card-nav-shop"></div>
                </Card>
              </Link>
            </Col>
          </Row>
          <Row xs={1} md={3} className="g-4">
            <Col sm={{ span: 4, offset: 1 }}>
              <Link to="/hydration" aria-current="page" className="nav-link">
                <Card className="card-design-shop-product-four">
                  <FontAwesomeIcon icon={faTShirt} />
                  <div className="card-nav-shop"></div>
                </Card>
              </Link>
            </Col>
          </Row>
          <Row xs={1} md={3} className="g-4">
            <Col sm={{ span: 4, offset: 1 }}>
              <Link to="/hydration" aria-current="page" className="nav-link">
                <Card className="card-design-shop-product-five">
                  <FontAwesomeIcon icon={faTShirt} />
                  <div className="card-nav-shop"></div>
                </Card>
              </Link>
            </Col>
          </Row>
          <Row xs={1} md={3} className="g-4">
            <Col sm={{ span: 4, offset: 1 }}>
              <Link to="/hydration" aria-current="page" className="nav-link">
                <Card className="card-design-shop-product-six">
                  <FontAwesomeIcon icon={faTShirt} />
                  <div className="card-nav-shop"></div>
                </Card>
              </Link>
            </Col>
          </Row>
          <Card className="card-design-shop-three">
            <Row>
              <Col sm={{ span: 4, offset: 1 }}></Col>
            </Row>
          </Card>
          <footer>
            <Row>
              <Col sm={{ span: 6, offset: 3 }}>
                <p className="copy">Copyright &copy; 2023</p>
              </Col>
            </Row>
          </footer>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default Shop;
